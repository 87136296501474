/* eslint-disable react/destructuring-assignment */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';

const SEO = (props: any) => {
  const { strapiLandingHome } = useStaticQuery(graphql`
    query {
      strapiLandingHome {
        pageTitle
        pageDescription
      }
    }
  `);

  const { description, meta } = props;

  const metaDescription = description || strapiLandingHome.pageDescription;

  const templateTitle = props.title ? `Genopets | %s` : undefined;

  const title = props.title || strapiLandingHome.pageTitle;

  return (
    <Helmet
      title={title}
      titleTemplate={templateTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://genopets.me/imgs/genopets-og.png`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:image`,
          content: `https://genopets.me/imgs/genopets-og.png`,
        },
        {
          name: `twitter:creator`,
          content: `@mralbertchen`,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  meta: [],
  title: null,
  description: ``,
};

export default SEO;
