import { Box, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { GlitchHeading, Link, Subheading, Text, Title } from 'ui';
import geneImg from '../assets/svgs/gene-with-bg.svg';
import kiImg from '../assets/svgs/ki-with-bg.svg';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Token = () => (
  <Layout>
    <Seo
      title="Token"
      description="Genopets operates on a dual-token system that comprises KI & GENE Token, each with its own function and in-game utility."
    />
    <VStack
      w={{ base: `unset`, lg: `860px` }}
      h="auto"
      ml={{ base: 7, lg: `auto` }}
      mr={{ base: 7, lg: `auto` }}
      mt={{ base: `90px`, lg: `75px` }}
      mb={2}
      align="center"
      pos="relative"
    >
      <Box textAlign="left">
        <Subheading as="h5" size="sm" color="#BDFFF1">
          The
        </Subheading>
        <Title as="h1" color="#00FFC8" mb="8px">
          dual token system
        </Title>
        <Text size="sm" color="#BDFFF1">
          Genopets operates on a dual-token system that comprises KI and GENE
          Token, each with its own function and in-game utility. Check out our
          {` `}
          <Link href="https://whitepaper.genopets.me/">litepaper</Link> for more
          on the dual-token system.
        </Text>
      </Box>
      <HStack
        w="full"
        align="flex-start"
        flexDir={{ base: `column`, lg: `row` }}
        pt="170px"
        gap={8}
        paddingBottom={{ base: `200px`, lg: `100px` }}
      >
        <VStack
          w={{ base: `full`, lg: `50%` }}
          textAlign="left"
          align="flex-start"
          gap={2}
        >
          <Box>
            <GlitchHeading as="h3" size="xl" color="#FF7A00" mb={1}>
              ki token
            </GlitchHeading>
            <Text size="sm" color="#FFD9B6">
              KI Token is an in-game utility token Habitat owners are able to
              harvest by converting Energy from steps each day. KI is burned
              when used and has a max daily earning cap.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#FF7A00">
              Crystal Refinement
            </Subheading>
            <Text size="sm" color="#FFD9B6">
              Refine crystals each day using KI Tokens to extend the lifespan of
              your Habitat while producing the ingredients used to craft items.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#FF7A00">
              Alchemy & Crafting
            </Subheading>
            <Text size="sm" color="#FFD9B6">
              Combine refined crystals with KI Tokens in your habitat’s lab to
              start crafting. Combine crystals of all types to create in-game
              items & power ups.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#FF7A00">
              Terraforming
            </Subheading>
            <Text size="sm" color="#FFD9B6">
              Create additional Habitats using KI Token along with GENE, Refined
              Crystals, and a Terraform Seed to sell to other players or rent in
              the market.
            </Text>
          </Box>
        </VStack>
        <VStack
          w={{ base: `full`, lg: `50%` }}
          textAlign="left"
          align="flex-start"
          pt={{ base: `170px`, lg: `0px` }}
          style={{ marginInlineStart: `0px` }}
        >
          <Box>
            <GlitchHeading as="h3" size="xl" color="#00FFC8" mb={1}>
              gene token
            </GlitchHeading>
            <Text size="sm" color="#BDFFF1">
              GENE Token is the governance, staking, and utility token used for
              Genesis NFT sales, seasonal drops, and in-game for crafting
              valuable items. Stake GENE for rewards and in-game perks.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#00FFC8">
              Stake GENE for Rewards
            </Subheading>
            <Text size="sm" color="#BDFFF1">
              Earn benefits and rewards like reward pool GENE, private beta
              access, seasonal NFT drops, and future Energy boosts in-game.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#00FFC8">
              Exclusive Genesis Drops
            </Subheading>
            <Text size="sm" color="#BDFFF1">
              Participate in seasonal NFT sales, future treasure hunts, and
              Genesis NFT campaigns, and partnership drops using GENE Token.
            </Text>
          </Box>
          <Box>
            <Subheading as="h4" size="sm" color="#00FFC8">
              Governance Voting
            </Subheading>
            <Text size="sm" color="#BDFFF1">
              In the future, staking will be the mechanism through which the
              community will vote on decisions such as future token emissions
              and game mechanics.
            </Text>
          </Box>
        </VStack>
      </HStack>
      <Box
        pos="absolute"
        left={{ base: `auto`, lg: `6%` }}
        top={{ base: `6%`, lg: `5%` }}
        minW="310px"
        pointerEvents="none"
      >
        <img src={kiImg} alt="ki" />
      </Box>
      <Box
        pos="absolute"
        left={{ base: `auto`, lg: `54.25%` }}
        top={{ base: `45%`, lg: `-3.7%` }}
        minW="374px"
        pointerEvents="none"
      >
        <img src={geneImg} alt="gene" />
      </Box>
    </VStack>
  </Layout>
);

export default Token;
