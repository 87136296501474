/* eslint-disable no-restricted-imports */
import {
  Box,
  BoxProps,
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  keyframes,
} from '@chakra-ui/react';
import { HeadingSize, HeadingPropsBase } from './types';
import { getHeadingProps } from './utils/utils';

export type GlitchHeadingProps = HeadingPropsBase & {
  size: HeadingSize;
  wrapperProps?: BoxProps;
};

const glitchSkew = keyframes`
  0% { transform: skew(1deg); }
  33.3333333333% { transform: skew(2deg); }
  66.6666666667% { transform: skew(2deg); }
  100% { transform: skew(1deg); }
`;

const glitchAnimation = keyframes`
  0% {
    clip-path: inset(39% 0 103% 0);
    transform: skew(0.16deg);
  }
  5% {
    clip-path: inset(82% 0 79% 0);
    transform: skew(0.5deg);
  }
  10% {
    clip-path: inset(112% 0 13% 0);
    transform: skew(0.1deg);
  }
  15% {
    clip-path: inset(120% 0 46% 0);
    transform: skew(0.28deg);
  }
  20% {
    clip-path: inset(178% 0 56% 0);
    transform: skew(0.05deg);
  }
  25% {
    clip-path: inset(18% 0 140% 0);
    transform: skew(0.18deg);
  }
  30% {
    clip-path: inset(78% 0 182% 0);
    transform: skew(0.41deg);
  }
  35% {
    clip-path: inset(86% 0 131% 0);
    transform: skew(0.88deg);
  }
  40% {
    clip-path: inset(2% 0 143% 0);
    transform: skew(0.19deg);
  }
  45% {
    clip-path: inset(20% 0 196% 0);
    transform: skew(0.72deg);
  }
  50% {
    clip-path: inset(2% 0 169% 0);
    transform: skew(0.47deg);
  }
  55% {
    clip-path: inset(22% 0 20% 0);
    transform: skew(0.44deg);
  }
  60% {
    clip-path: inset(57% 0 45% 0);
    transform: skew(0.45deg);
  }
  65% {
    clip-path: inset(73% 0 % 0);
    transform: skew(0.41deg);
  }
  70% {
    clip-path: inset(37% 0 36% 0);
    transform: skew(0.05deg);
  }
  75% {
    clip-path: inset(21% 0 78% 0);
    transform: skew(0.17deg);
  }
  80% {
    clip-path: inset(8% 0 77% 0);
    transform: skew(0.6deg);
  }
  85% {
    clip-path: inset(39% 0 97% 0);
    transform: skew(0.6deg);
  }
  90% {
    clip-path: inset(86% 0 94% 0);
    transform: skew(0.34deg);
  }
  95% {
    clip-path: inset(0% 0 89% 0);
    transform: skew(0.38deg);
  }
  100% {
    clip-path: inset(13% 0 48% 0);
    transform: skew(0.41deg);
  }
`;

const glitchAnimationProps: ChakraHeadingProps = {
  pos: `absolute`,
  top: `0px`,
  w: `full`,
  opacity: 0.4,
  animation: `${glitchAnimation} 3s infinite linear alternate-reverse`,
};

export const GlitchHeading = ({
  children,
  as,
  size,
  wrapperProps,
  ...props
}: GlitchHeadingProps) => (
  <Box pos="relative" {...wrapperProps}>
    <ChakraHeading
      {...{
        ...glitchAnimationProps,
        ...props,
        ...getHeadingProps(size),
        as,
        left: `2px`,
        textShadow: `-2px 0 ${props.color}`,
        animation: `${glitchAnimation} 3s infinite linear alternate-reverse`,
      }}
    >
      {children}
    </ChakraHeading>

    <ChakraHeading
      {...{
        pos: `relative`,
        animation: `${glitchSkew} 1s infinite linear alternate-reverse`,
        ...props,
        ...getHeadingProps(size),
        as,
      }}
    >
      {children}
    </ChakraHeading>

    <ChakraHeading
      {...{
        ...glitchAnimationProps,
        ...props,
        ...getHeadingProps(size),
        as,
        left: `-2px`,
        textShadow: `2px 0 ${props.color}`,
      }}
    >
      {children}
    </ChakraHeading>
  </Box>
);
