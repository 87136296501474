import {
  // eslint-disable-next-line no-restricted-imports
  Heading as ChakraHeading,
} from '@chakra-ui/react';
import { SubheadingProps } from './types';
import { getSubheadingProps } from './utils/utils';

export const Subheading = ({
  children,
  size,
  as,
  ...props
}: SubheadingProps) => (
  <ChakraHeading
    {...{
      ...props,
      ...getSubheadingProps(size),
      as,
    }}
  >
    {children}
  </ChakraHeading>
);
