import {
  // eslint-disable-next-line no-restricted-imports
  Heading,
} from '@chakra-ui/react';
import { TitleProps } from './types';
import { baseTitleProps, getTitleProps } from './utils/utils';

export const Title = ({
  children,
  as,
  size = `3xl`,
  filledColor,
  ...props
}: TitleProps) => (
  <Heading
    {...{
      ...(size
        ? getTitleProps({ size, filledColor, ...props })
        : baseTitleProps({ ...props, filledColor })),
      ...props,
      as,
    }}
  >
    {children}
  </Heading>
);
